import { graphql, useStaticQuery } from "gatsby";

export const Photos = () => {
  const images = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "images/landings/b2b/hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      photo_1: file(relativePath: { eq: "images/landings/b2b/photo_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      photo_2: file(relativePath: { eq: "images/landings/b2b/photo_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      photo_3: file(relativePath: { eq: "images/landings/b2b/photo_3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      photo_4: file(relativePath: { eq: "images/landings/b2b/photo_4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return images;
};
