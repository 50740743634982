import { graphql, useStaticQuery } from "gatsby";

export const Photos = () => {
  const images = useStaticQuery(graphql`
    query {
      hero: file(
        relativePath: { eq: "images/landings/deweloper/photo_1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      photo_1: file(
        relativePath: { eq: "images/landings/deweloper/hero.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      photo_2: file(
        relativePath: { eq: "images/landings/deweloper/photo_2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      photo_3: file(
        relativePath: { eq: "images/landings/deweloper/photo_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return images;
};
