import React from "react";
import Image from "gatsby-image";
import { Photos } from "./photos";

export const MOTO_data = () => {
  const images = Photos();
  return {
    seo: {
      title: "Rozwiązania dla motoryzacji",
      description:
        "Kupno samochodu jest długim procesem zakupowym, jednak wiemy, co zrobić, by klient chciał przez niego przejść. Sprawiamy, że auta stają się marzeniami, które będą chciały zostać zrealizowane.",
    },
    hero: {
      title: "Marketing dla motoryzacji",
      text: (
        <>
          Kupno samochodu jest długim procesem zakupowym, jednak wiemy, co
          zrobić, by klient chciał przez niego przejść. Sprawiamy, że auta stają
          się marzeniami, które będą chciały zostać zrealizowane. Wiemy, jak
          skutecznie poprowadzić <b>działania marketingowe,</b> aby Twoja marka
          stała się liderem wśród sprzedawców i cieszyła się zaufaniem. Zdaj się
          na naszych doświadczonych specjalistów.
        </>
      ),
      image: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"cover"}
          fluid={images.hero.childImageSharp.fluid}
        />
      ),
    },
    text_sections: {
      first: {
        title: "Kreujemy leady dla dealerów",
        text: (
          <>
            Już od ponad dekady nieprzerwanie działamy w branży moto. W tym
            czasie znacznie powiększyliśmy grono naszych partnerów, bo wiemy,
            jak pozyskiwać leady dla branży motoryzacyjnej. Używamy do tego
            całego spektrum możliwości nowoczesnych narzędzi takich jak{" "}
            <b>media społecznościowe czy Google Ads.</b> Precyzyjnie dobieramy
            grupy docelowe, tworzymy kreacje przyciągające wzrok klienta i
            przygotowujemy dopracowane w każdym szczególe formularze. Dzięki
            temu kreujemy wartościowe leady dla naszych klientów.
          </>
        ),
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.photo_1.childImageSharp.fluid}
          />
        ),
        button: "Porozmawiaj z nami",
      },
      second: {
        title: "Angażujemy i sprzedajemy w social mediach",
        text: (
          <>
            Social media to doskonałe narzędzie, które służy do tworzenia
            zaangażowania klientów, a co za tym idzie - zwiększa sprzedaż.
            Wiemy, jak to zrobić. Posiadamy duże doświadczenie w budowaniu
            relacji z odbiorcami w internecie i potrafimy wykorzystać potencjał
            planowanych działań. <b>Social media</b> pozwalają na działania
            wizerunkowe i sprzedażowe - wykorzystaj je.
          </>
        ),
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.photo_2.childImageSharp.fluid}
          />
        ),
        button: "Porozmawiaj z nami",
      },
      third: {
        title: "Prowadzimy kampanie Google Ads",
        text: (
          <>
            <b>Pozycjonowanie i Google Ads</b> to świetne narzędzia
            umożliwiające dotarcie do grupy klientów zainteresowanej kupnem
            samochodu. Ich zalety to niewątpliwie kierowanie reklamami tak, by
            odpowiadały na potrzeby odbiorców, kontrola nad kosztami czy
            mierzenie efektów. Narzędzia te również doskonale sprawdzają przy
            budowaniu pozytywnego wizerunku Twojej firmy.
          </>
        ),
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.photo_3.childImageSharp.fluid}
          />
        ),
        button: "Porozmawiaj z nami",
      },
      fourth: {
        title: "Zdjęcia, które sprzedają",
        text: (
          <>
            Zdjęcia i wideo to najważniejsze elementy reklamy motoryzacyjnej.
            Poprzez <b>sesje zdjęciowe i filmy</b> możesz pokazać nie tylko
            auta, którymi handlujesz, ale też filozofię swojej marki i styl, z
            jakim się identyfikuje. Wideo to również jeden z najbardziej
            efektywnych sposobów na promocję w internecie. Wzbudź zachwyt i
            zaciekawienie klienta oraz chęć skontaktowania się - to pierwszy
            krok w stronę zakupu.
          </>
        ),
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.photo_4.childImageSharp.fluid}
          />
        ),
        button: "Porozmawiaj z nami",
      },
    },
  };
};
