import React from "react";
import Image from "gatsby-image";
import { Photos } from "./photos";

export const DEWELOPER_data = () => {
  const images = Photos();
  return {
    seo: {
      title: "Rozwiązania dla deweloperów",
      description:
        "Nadaj charakteru swojej inwestycji! Wykreujemy każdy projekt, który będzie utożsamiał wartości ważne dla docelowej grupy nabywców Twoich domów i mieszkań. ",
    },
    hero: {
      title: "Branding - unikalność Twojej inwestycji",
      text: (
        <>
          Nadaj charakteru swojej inwestycji! Wykreujemy każdy projekt, który
          będzie utożsamiał wartości ważne dla docelowej grupy nabywców Twoich
          domów i mieszkań. Sprawimy, że każde wybudowane przez Ciebie osiedle
          będzie wyróżniało się na tle innych i zajmie optymalne miejsce w
          świadomości klientów. To wszystko przyczyni się do szybszej sprzedaży
          wszystkich dostępnych mieszkań.
        </>
      ),
      image: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"cover"}
          fluid={images.photo_1.childImageSharp.fluid}
        />
      ),
    },
    text_sections: {
      first: {
        title: "Strony internetowe, które pozyskują klientów ",
        text: (
          <>
            Sprzedaj mieszkania szybciej niż konkurencja dzięki przejrzystej i
            czytelnej stronie internetowej. Wykonamy dla Ciebie profesjonalną
            witrynę wraz z prezentacją lokali, co wzmocni efekt sprzedażowy.
            Projektujemy strony WWW, które generują leady oraz wiemy, jakie
            rozwiązania wdrożyć, by strona była nowoczesna i funkcjonalna.
          </>
        ),
        button: "Porozmawiaj z nami",
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.hero.childImageSharp.fluid}
          />
        ),
      },
      second: {
        title: "Internet - zbuduj relację z klientem  ",
        list: {
          title:
            "Zoptymalizuj koszty związane z reklamą i rozpowszechnij informacje o swojej inwestycji w internecie! Oferujemy:",
          list: [
            "Prowadzenie profili i kampanii reklamowych w social mediach",
            "Pozycjonowanie i GoogleAds",
            "Reklamy w mediach i na portalach internetowych.",
          ],
        },
        text:
          "Dzięki takim działaniom trafisz z reklamą do osób aktywnie poszukujących nieruchomości, a co więcej - Twoja inwestycja będzie towarzyszyła klientom przez cały proces decyzyjny.",
        button: "Porozmawiaj z nami",
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.photo_3.childImageSharp.fluid}
          />
        ),
      },
      third: {
        title: "Reklama outdoorowa - poinformuj miasto o swojej inwestycji",
        text: (
          <>
            Zaprezentuj swoje osiedle nie tylko wirtualnie. Dla deweloperów
            bardzo ważna jest również <b>reklama outdoorowa.</b> Poinformuj
            wszystkich mieszkańców miasta o swojej inwestycji, zainteresuj ich i
            skłoń do zakupu. Projektujemy banery, które zwracają uwagę
            potencjalnie zainteresowanych i zapadają im w pamięć, dzięki czemu
            zechcą wyszukać więcej informacji o Twojej inwestycji.
          </>
        ),
        button: "Porozmawiaj z nami",
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.photo_2.childImageSharp.fluid}
          />
        ),
      },
    },
  };
};
