import LandingName from "shared/Types/landings";

import { B2B_data } from "./b2b/data";
import { B2C_data } from "./b2c/data";
import { MOTO_data } from "./moto/data";
import { DEWELOPER_data } from "./deweloper/data";

export const useLandingsData = (name) => {
  const data = {
    [LandingName.B2B]: B2B_data(),
    [LandingName.B2C]: B2C_data(),
    [LandingName.MOTO]: MOTO_data(),
    [LandingName.DEWELOPER]: DEWELOPER_data(),
  };
  if (!name) {
    return data;
  }
  return data[name];
};
