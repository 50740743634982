import React from "react";
import Image from "gatsby-image";
import { Photos } from "./photos";
import BorimexVideo from "assets/videos/borimex.mp4";

export const B2B_data = () => {
  const images = Photos();
  return {
    seo: {
      title: "Rozwiązania - B2B",
      description:
        "Strona internetowa to miejsce, gdzie potencjalni klienci i partnerzy biznesowi spotykają Twoją firmę po raz pierwszy.",
    },
    hero: {
      title: "Strona internetowa, która podkreśli Twoją jakość i nowoczesność.",
      text: (
        <>
          <b>Strona internetowa</b> to miejsce, gdzie potencjalni klienci i
          partnerzy biznesowi spotykają Twoją firmę po raz pierwszy. Pokaż, że
          jest ona nowoczesna nie tylko z nazwy. Projektowaniem stron
          internetowych zajmujemy się już od ponad dekady. Tworzymy
          witryny, które eksponują wartość i mocne strony Twojej firmy oraz
          podkreślają Twoje wysokie kwalifikacje i mocne strony.
        </>
      ),
      image: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"cover"}
          fluid={images.hero.childImageSharp.fluid}
        />
      ),
    },
    text_sections: {
      first: {
        title: "Social media - nowoczesne centrum komunikacji.",
        text: (
          <>
            Chcesz, by Twoja firma była postrzegana jako nowoczesna i
            otwarta? Social media to potężne narzędzie, które nie tylko zbuduje
            wizerunek i rozpoznawalność Twojej marki, ale stanowi też świetny
            sposób na dotarcie do nowych klientów. Pokaż, że jesteś dumny z
            tego, co robisz i w jaki sposób się rozwijasz.{" "}
            <b>Social media marketing</b> to jedna z najtańszych i najbardziej
            efektywnych form reklamy.
          </>
        ),
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.photo_1.childImageSharp.fluid}
          />
        ),
        button: "Porozmawiaj z nami",
      },
      second: {
        title: "System identyfikacji wizualnej podkreślający Twój charakter. ",
        list: {
          title: "Dzięki profesjonalnemu systemowi identyfikacji wizualnej:",
          list: [
            "pokażesz, że dbasz o detale i jakość w każdym aspekcie",
            "podkreślisz globalny status swojej firmy",
            "ujednolicisz wszystkie elementy firmowe, co korzystnie wpłynie na rozpoznawalność Twojej firmy",
            "udowodnisz, że jesteś poważnym partnerem dbającym o wysokie standardy w każdym wymiarze",
          ],
        },
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.photo_3.childImageSharp.fluid}
          />
        ),
        button: "Wyceń projekt",
      },
      third: {
        title: "Gadżety firmowe - efektywny sposób budowania relacji",
        text: (
          <>
            Wyróżnij się podczas targów i imprez branżowych, zostaw po sobie
            dobre wrażenie wśród biznesowych partnerów, nawiąż silniejsze
            relacje z klientami zainteresowanymi Twoją ofertą.{" "}
            <b>Gadżety i materiały reklamowe</b> pozytywnie wzmocnią wizerunek
            Twojej marki jako partnera dbającego o jakość i najmniejsze
            szczegóły w każdej dziedzinie.
          </>
        ),
        image: (
          <Image
            alt=""
            objectFit={"contain"}
            fluid={images.photo_2.childImageSharp.fluid}
          />
        ),
        button: "Wyceń projekt",
      },
      fourth: {
        title: "Druk reklamowy - nowoczesny przekaz w tradycyjnej formie",
        list: {
          title:
            "Tradycyjne formy przekazu nadal sprawdzają się przy prowadzeniu każdej działalności. Oferujemy druk reklamowy:",
          list: [
            "wizytówek, aby Twoi partnerzy mieli kontakt do Ciebie zawsze pod ręką",
            "ulotek, które pomogą przy pierwszym kontakcie z Twoją ofertą",
            "kalendarzy, które będą towarzyszyć Twoim partnerom przez cały rok i zorganizują Twoje miejsce pracy",
          ],
        },
        image: (
          <Image
            alt=""
            objectFit={"contain"}
            fluid={images.photo_4.childImageSharp.fluid}
          />
        ),
        button: "Porozmawiaj z nami",
      },
    },
    videos: {
      first: {
        title: "Wideo i zdjęcia pokazujące wysoką jakość Twoich usług",
        text: (
          <>
            Pokaż, że Twoja marka jest odpowiedzią na oczekiwania klientów.
            <b>
              {" "}
              Profesjonalne fotografie biznesowe i produktowe oraz wideo
            </b>{" "}
            to narzędzia, które świetnie wspomagają sprzedaż produktów.
            Wykorzystaj potencjał własnej marki, zbuduj zaufanie wśród partnerów
            i wyeksponuj wysoką jakość Twoich usług na fotografiach lub filmach,
            które możemy dla Ciebie wykonać.
          </>
        ),
        video: BorimexVideo,
        button: "Wyceń projekt",
      },
    },
  };
};
