export const B2B = "B2B";
export const B2C = "B2C";
export const MOTO = "MOTO";
export const DEWELOPER = "DEWELOPER";

export default {
  B2B,
  B2C,
  MOTO,
  DEWELOPER,
};
