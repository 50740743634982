import React from "react";
import Image from "gatsby-image";
import { Photos } from "./photos";
import MatthiasVideo from "assets/videos/matthias.mp4";

export const B2C_data = () => {
  const images = Photos();
  return {
    seo: {
      title: "Rozwiązania - B2C",
      description:
        "Strona internetowa to Twoja wizytówka, na którą trafiają klienci chcący poznać produkty, które sprzedajesz.",
    },
    hero: {
      title: "Strona internetowa, która przyciągnie klientów",
      text: (
        <>
          <b>Strona internetowa</b> to Twoja wizytówka, na którą trafiają
          klienci chcący poznać produkty, które sprzedajesz. Pokaż, że dbasz o
          szczegóły i jakość w każdym aspekcie. Zaprezentuj się jako marka
          sprzedająca produkty godne zaufania. Projektujemy witryny
          internetowe przyjazne użytkownikom, które zachęcają do zapoznania się
          z marką i pokazują jej pełny profesjonalizm.
        </>
      ),
      image: (
        <Image
          alt=""
          objectPosition={"center"}
          objectFit={"cover"}
          fluid={images.hero.childImageSharp.fluid}
        />
      ),
    },
    text_sections: {
      first: {
        title: "Social media - źródło informacji o marce",
        text: (
          <>
            Marketing w <b>mediach społecznościowych</b> to pierwszy krok, który
            pomoże wypozycjonować Twój sklep online. To właśnie to miejsce, w
            którym Twój klient powinien poczuć się komfortowo i zapoznać z
            działalnością marki. Zaplanujemy kampanie i treści, które podkreślą
            wyjątkowość oferowanych usług. Zdaj się na nas!
          </>
        ),
        button: "Porozmawiaj z nami",
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.photo_1.childImageSharp.fluid}
          />
        ),
      },
      second: {
        title: "Identyfikacja wizualna budująca rozpoznawalność",
        text: (
          <>
            Wyróżnij się na tle konkurencji i podkreśl wysokie kompetencje
            swojej firmy już przy pierwszym spotkaniu. Opracujemy
            elementy systemu identyfikacji wizualnej, które pokażą, że dbasz o
            detale i jakość w każdym aspekcie. Sprawimy, że Twoi klienci
            zrozumieją, że jesteś poważnym partnerem w biznesie. 
            <b>System identyfikacji wizualnej</b> - spójny, nowoczesny i zgodny
            z profilem działalności - wyeksponuje Twoje przedsiębiorstwo na
            rynku.
          </>
        ),
        button: "Porozmawiaj z nami",
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.photo_2.childImageSharp.fluid}
          />
        ),
      },
      third: {
        title: "Opakowania przyciągające uwagę klienta",
        text: (
          <>
            Opakowanie to często najważniejszy czynnik decydujący o zakupie:
            klient, który nie zwrócił uwagi na produkt, nie kupi go. Wyróżnij
            się na półce i przyciągnij wzrok dzięki zaprojektowanym przez nas
            opakowaniom. Pamiętaj, że to najczęściej przy półce klient podejmuje
            najważniejsze zakupowe decyzje, dlatego{" "}
            <b>odpowiednia oprawa Twoich artykułów</b> powinna być dla Ciebie
            równie ważna.
          </>
        ),
        button: "Porozmawiaj z nami",
        image: (
          <Image
            alt=""
            objectPosition={"center"}
            objectFit={"cover"}
            fluid={images.photo_3.childImageSharp.fluid}
          />
        ),
      },
    },
    videos: {
      first: {
        title: "Zdjęcia produktowe wspierające sprzedaż",
        text: (
          <>
            Dobre zdjęcia są niezwykle ważne w Twojej działalności. Pokaż swoją
            jakość na profesjonalnych ujęciach fotograficznych, które chcemy dla
            Ciebie wykonać. Stworzymy także video, które uwidoczni wszystkie
            wyjątkowe detale oferowanych produktów. Twoi klienci chcą Cię
            widzieć, a dzięki temu zwrócisz na siebie ich uwagę.
          </>
        ),
        video: MatthiasVideo,
        button: "Wyceń projekt",
      },
    },
  };
};
