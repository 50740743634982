import React from "react";
import SEO from "components/seo";
import {
  ContactForm,
  FooterVideo,
  FullPhoto,
  Hero,
  TextBox,
  Testimonials,
  VideoBox,
} from "components/LandingLayout";
import { B2C } from "shared/Types/landings";
import { useLandingsData } from "shared/Hooks/Landings/useLandingsData";

const Landing = () => {
  const data = useLandingsData(B2C);
  return (
    <>
      <SEO title={data.seo.title} description={data.seo.description} />
      <Hero data={data.hero} />
      <FullPhoto data={data.text_sections.first} />
      <Testimonials />
      <VideoBox data={data.videos.first} />
      <TextBox data={data.text_sections.second} />
      <TextBox data={data.text_sections.third} reverse={true} />
      <FooterVideo />
      <ContactForm />
    </>
  );
};

export default Landing;
